import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Message, MessageType } from '../types';
import {LightTheme} from '../styles/colors';

interface MessageProps {
  message: Message;
}

const MessageComponent: React.FC<MessageProps> = ({ message }) => {
  const formattedTime = message.timestamp.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });

  const renderMessageContent = () => {
    switch (message.type) {
      case MessageType.QR_CODE:
        return (
          <img
            src={message.text}
            alt="QR Code"
            style={styles.qrCode}
          />
        );
      case MessageType.TOOL_USE:
        return <div style={styles.toolUse}>{message.text}</div>;
      case MessageType.IMAGE:
        return (
          <img
            src={message.text}
            alt="Uploaded image"
            style={styles.uploadedImage}
          />
        );
      case MessageType.TEXT:
        return (
          <ReactMarkdown
            components={{
              a: ({ node, ...props }) => (
                <a target="_blank" rel="noopener noreferrer" {...props} style={styles.link} />
              ),
              img: ({ node, ...props }) => (
                <img {...props} style={styles.image} />
              ),
            }}
            children={message.text}
          />
        );
      case MessageType.ERROR:
        return <div style={{ color: 'red' }}>{message.text}</div>;
      default:
        return <div>{message.text}</div>;
    }
  };

  return (
    <div style={{
      ...styles.message,
      ...(message.sender === 'user' ? styles.userMessage : styles.botMessage)
    }}>
      <div style={styles.messageContent}>
        {renderMessageContent()}
      </div>
      <div style={{
        ...styles.messageTime,
        ...(message.sender === 'user' ? styles.userMessageTime : styles.botMessageTime)
      }}>
        {formattedTime}
      </div>
    </div>
  );
};

const styles = {
  message: {
    marginBottom: '10px',
    fontSize: '14px',
    color: LightTheme.textColor,
  },
  userMessage: {
    alignSelf: 'flex-end',
  },
  botMessage: {
    alignSelf: 'flex-start',
  },
  messageContent: {
    padding: '0px 15px',
  },
  link: {
    color: LightTheme.primaryAI,
    textDecoration: 'underline',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    margin: '5px 0',
  },
  qrCode: {
    width: '300px',
    height: '300px',
    marginTop: '10px',
  },
  messageTime: {
    fontSize: '12px',
    marginTop: '5px',
    opacity: 0.7,
  },
  userMessageTime: {
    textAlign: 'right' as const,
    paddingRight: '15px',
  },
  botMessageTime: {
    textAlign: 'left' as const,
    paddingLeft: '15px',
  },
  toolUse: {
    marginTop: '10px',
    fontStyle: 'italic',
    fontSize: '12px',
    opacity: 0.8,
  },
  uploadedImage: {
    maxWidth: '300px',
    maxHeight: '300px',
    borderRadius: '8px',
    objectFit: 'contain' as const,
    margin: '5px 0',
  },
};

export default MessageComponent;