export enum MessageType {
    TEXT,
    TOOL_USE,
    QR_CODE,
    IMAGE,
    ERROR
  }

export interface Message {
    id: string;
    text: string;
    sender: 'user' | 'bot';
    timestamp: Date;
    // qrCodeUrl?: string;
    type: MessageType;
}

export interface TokenHolding {
    name: string;
    thumbnail: string | undefined;
    decimals: number;
    symbol: string;
    balance: string;
    address: string | undefined;
    usd_value: number;
    usd_price: string;
    possible_spam: boolean;
    chainId: number;
    usdValue24hrUsdChange: string;
    type: "erc20" | "erc721" | "erc1155" | "native" | "unknown";
    id?: string;
  }