import React from 'react';
import { LightTheme } from '../styles/colors';
import { Link } from 'react-router-dom';

const Support: React.FC = () => {
  return (
    <div style={styles.container}>
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Contact us</h2>
        <p style={styles.text}>
          If you need to contact support, please send an email to{' '}
          <a href="mailto:support@dittowallet.com" style={styles.link}>support@dittowallet.com</a>,
          or on Twitter{' '}
          <a href="https://x.com/dittowallet" target="_blank" rel="noopener noreferrer" style={styles.link}>
            @dittowallet
          </a>
        </p>
      </section>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '40px 20px',
    color: LightTheme.textColor,
    textAlign: 'left' as const,
  },
  title: {
    fontSize: '2rem',
    marginBottom: '30px',
    textAlign: 'left' as const,
  },
  section: {
    marginBottom: '40px',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: LightTheme.textColor,
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  list: {
    listStyleType: 'none',
    padding: '0',
    marginBottom: '20px',
  },
  link: {
    color: LightTheme.textColor,
    textDecoration: 'underline',
    fontSize: '1rem',
    lineHeight: '2',
    transition: 'color 0.3s ease',
    ':hover': {
      color: LightTheme.textColor,
    },
  },
};

export default Support;
